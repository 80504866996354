export default [
  {
    "id": 0,
    "imageUrl": "roofTypes",
    "subtext": "",
    "type": "roofType",
    "variants": [
      { id: 'Pulpettak', artNumber: 'Pulpettak', name: 'Pulpettak'},
      { id: 'Övriga tak', artNumber: 'Övriga tak', name: 'Övriga tak'}
    ],
  },
  {
  "width": 280,
  "cc":250,
  "maxDistanceConsole":750,
  "height": 60,
  "id": 1,
  "imageUrl": "rooftiles",
  "length": 420,
  "manual": null,
  "nPerPall": 210,
  "bundle": null,
  "name": "Carisma platt",
  "series": "Carisma platt",
  "subtext": "",
  "type": "rooftile",
  "variants": [
    { artNumber: "C00021", family: 'Carisma', treatment: 'Benderit', color: 'Granit'},
    { artNumber: "C00028", family: 'Carisma', treatment: 'Benderit', color: 'Mellangr\u00e5'},
    { artNumber: "C00024", family: 'Carisma', treatment: 'Benderit', color: 'Tegelr\u00f6d'},
    { artNumber: "C00051", family: 'Carisma', treatment: 'Brilliant', color: 'Skiffer'},
    { artNumber: "C00001", family: 'Carisma', treatment: 'Obehandlad', color: 'Grafit'},
  ],
  "weight": 4.5
},
{
  "width": 280,
  "cc":250,
  "maxDistanceConsole":750,
  "height": 100,
  "id": 2,
  "imageUrl": "rooftiles",
  "length": 420,
  "manual": null,
  "nPerPall": 240,
  "bundle": null,
  "name": "Exklusiv 1-kupig",
  "series": "Exklusiv 1-kupig",
  "subtext": "",
  "type": "rooftile",
  "variants": [
    { artNumber: "010022", family: 'Exklusiv', treatment: 'Benderit', color: 'Brun'},
    { artNumber: "010029", family: 'Exklusiv', treatment: 'Benderit', color: 'Flash tegelr\u00f6d/brun'},
    { artNumber: "010023", family: 'Exklusiv', treatment: 'Benderit', color: 'Gr\u00f6n'},
    { artNumber: "010025", family: 'Exklusiv', treatment: 'Benderit', color: 'Gul'},
    { artNumber: "010028", family: 'Exklusiv', treatment: 'Benderit', color: 'Mellangr\u00e5'},
    { artNumber: "010026", family: 'Exklusiv', treatment: 'Benderit', color: 'R\u00f6d'},
    { artNumber: "010020", family: 'Exklusiv', treatment: 'Benderit', color: 'Svart'},
    { artNumber: "010024", family: 'Exklusiv', treatment: 'Benderit', color: 'Tegelr\u00f6d'},
    { artNumber: "010051", family: 'Exklusiv', treatment: 'Brilliant', color: 'Skiffer'},
    { artNumber: "010088", family: 'Exklusiv', treatment: 'Candor', color: 'Mellangr\u00e5'},
    { artNumber: "010098", family: 'Exklusiv', treatment: 'Candor', color: 'M\u00f6rkgr\u00e5'},
    { artNumber: "010080", family: 'Exklusiv', treatment: 'Candor', color: 'Svart'},
    { artNumber: "010084", family: 'Exklusiv', treatment: 'Candor', color: 'Tegelr\u00f6d'},
    { artNumber: "010086", family: 'Exklusiv', treatment: 'Candor', color: 'Terracottar\u00f6d'},
    { artNumber: "010001", family: 'Exklusiv', treatment: 'Obehandlad', color: 'Grafit'},
    { artNumber: "010019", family: 'Exklusiv', treatment: 'Obehandlad', color: 'R\u00f6d standard'},
  ],
  "weight": 3.9,
},
{
  "width": 330,
  "cc":300,
  "maxDistanceConsole":600,
  "height": 75,
  "id": 3,
  "imageUrl": "rooftiles",
  "length": 420,
  "manual": null,
  "nPerPall": 240,
  "bundle": null,
  "name": "Palema 2-kupig",
  "series": "Palema 2-kupig",
  "subtext": "",
  "type": "rooftile",
  "variants": [
    { artNumber: "020022", family: 'Palema', treatment: 'Benderit', color: 'Brun'},
    { artNumber: "020029", family: 'Palema', treatment: 'Benderit', color: 'Flash tegelr\u00f6d/brun'},
    { artNumber: "020023", family: 'Palema', treatment: 'Benderit', color: 'Gr\u00f6n'},
    { artNumber: "020025", family: 'Palema', treatment: 'Benderit', color: 'Gul'},
    { artNumber: "020037", family: 'Palema', treatment: 'Benderit', color: 'Ljusgr\u00e5'},
    { artNumber: "020028", family: 'Palema', treatment: 'Benderit', color: 'Mellangr\u00e5'},
    { artNumber: "020048", family: 'Palema', treatment: 'Benderit', color: 'M\u00f6rkgr\u00e5'},
    { artNumber: "020026", family: 'Palema', treatment: 'Benderit', color: 'R\u00f6d'},
    { artNumber: "020020", family: 'Palema', treatment: 'Benderit', color: 'Svart'},
    { artNumber: "020024", family: 'Palema', treatment: 'Benderit', color: 'Tegelr\u00f6d'},
    { artNumber: "020031", family: 'Palema', treatment: 'Benderit', color: 'Vinr\u00f6d'},
    { artNumber: "020055", family: 'Palema', treatment: 'Brilliant', color: 'Koppar'},
    { artNumber: "020051", family: 'Palema', treatment: 'Brilliant', color: 'Skiffer'},
    { artNumber: "020082", family: 'Palema', treatment: 'Candor', color: 'Kastanjebrun'},
    { artNumber: "020088", family: 'Palema', treatment: 'Candor', color: 'Mellangr\u00e5'},
    { artNumber: "020098", family: 'Palema', treatment: 'Candor', color: 'M\u00f6rkgr\u00e5'},
    { artNumber: "020083", family: 'Palema', treatment: 'Candor', color: 'Olivgr\u00f6n'},
    { artNumber: "020080", family: 'Palema', treatment: 'Candor', color: 'Svart'},
    { artNumber: "020084", family: 'Palema', treatment: 'Candor', color: 'Tegelr\u00f6d'},
    { artNumber: "020086", family: 'Palema', treatment: 'Candor', color: 'Terracottar\u00f6d'},
    // { artNumber: "020028E", family: 'Palema', treatment: 'ECO-line Benderit', color: 'Mellangr\u00e5'},
    // { artNumber: "020024E", family: 'Palema', treatment: 'ECO-line Benderit', color: 'Tegelr\u00f6d'},
    { artNumber: "020014", family: 'Palema', treatment: 'Obehandlad', color: 'Antik tegelr\u00f6d standard/svart'},
    { artNumber: "020001", family: 'Palema', treatment: 'Obehandlad', color: 'Grafit'},
    { artNumber: "020019", family: 'Palema', treatment: 'Obehandlad', color: 'R\u00f6d standard'},
    // { artNumber: "020014E", family: 'Palema', treatment: 'ECO-line Obehandlad', color: 'Tegelr\u00f6d standard'},
    { artNumber: "020078", family: 'Palema', treatment: 'Struktur', color: 'Mellangr\u00e5'},
    { artNumber: "020076", family: 'Palema', treatment: 'Struktur', color: 'R\u00f6d'},
    { artNumber: "020070", family: 'Palema', treatment: 'Struktur', color: 'Svart'},
    { artNumber: "020074", family: 'Palema', treatment: 'Struktur', color: 'Tegelr\u00f6d'},
  ],
  "weight": 4.05
},
{
  "width": 264,
  "cc":210,
  "maxDistanceConsole":633,
  "height": 95,
  "id": 4,
  "imageUrl": "rooftiles",
  "length": 425,
  "manual": null,
  "nPerPall": 360,
  "bundle": null,
  "name": "Hansa 1-kupig",
  "series": "Hansa 1-kupig",
  "subtext": "",
  "type": "rooftile",
  "variants": [
    { artNumber: "H10024", family: 'Hansa 1-kupig', treatment: 'Natur', color: 'Naturröd'},
    { artNumber: "H10026", family: 'Hansa 1-kupig', treatment: 'Engoberad', color: 'Gammalröd'},
    { artNumber: "H10084", family: 'Hansa 1-kupig', treatment: 'Glaserad', color: 'Bongossibrun'},
    { artNumber: "H10086", family: 'Hansa 1-kupig', treatment: 'Glaserad', color: 'Indiaröd'},
    { artNumber: "H10087", family: 'Hansa 1-kupig', treatment: 'Glaserad', color: 'Titansvart'},
  ],
  "weight": 2.9
},
{
  "width": 398,
  "cc":346,
  "maxDistanceConsole":692,
  "height": 90,
  "id": 5,
  "imageUrl": "rooftiles",
  "length": 365,
  "manual": null,
  "nPerPall": 192,
  "bundle": null,
  "name": "Tvilling 2-kupig",
  "series": "Tvilling 2-kupig",
  "subtext": "",
  "type": "rooftile",
  "variants": [
    { artNumber: "H20024", family: 'Tvilling 2-kupig', treatment: 'Natur', color: 'Naturröd'},
    { artNumber: "H20080", family: 'Tvilling 2-kupig', treatment: 'Kristallengoberad', color: 'Grafit'},
    { artNumber: "H20082", family: 'Tvilling 2-kupig', treatment: 'Glaserad', color: 'Nötbrun'},
    { artNumber: "H20087", family: 'Tvilling 2-kupig', treatment: 'Glaserad', color: 'Titansvart'},
  ],
  "weight": 4.5
},
{
  "width": 240,
  "cc":204,
  "maxDistanceConsole":612,
  "height": 75,
  "id": 6,
  "imageUrl": "rooftiles",
  "length": 410,
  "manual": null,
  "nPerPall": 240,
  "bundle": null,
  "name": "Piano platt",
  "series": "Piano platt",
  "subtext": "",
  "type": "rooftile",
  "variants": [
    { artNumber: "H00020", family: 'Piano platt', treatment: 'Engoberad', color: 'Skiffersvart'},
    { artNumber: "H00027", family: 'Piano platt', treatment: 'Engoberad', color: 'Platinagrå'},
    { artNumber: "H00080", family: 'Piano platt', treatment: 'Kristallengoberad', color: 'Grafit'},
  ],
  "weight": 3.1
},
{
  "width": null,
  "height": null,
  "id": 7,
  "imageUrl": "snowsafety",
  "length": 2100,
  "manual": null,
  "nPerPall": null,
  "bundle": null,
  "name": "Snörasskydd Bender Safe 2,1m",
  "series": null,
  "subtext": "",
  "type": "snowSafety",
  "variants": [
    { artNumber: "172420", color: 'Svart'},
    { artNumber: "172424", color: 'Tegelröd'},
    { artNumber: "172490", color: 'Galvad'},
  ],
  "weight": 3.55
},
{
  "width": null,
  "height": null,
  "id": 8,
  "imageUrl": "snowsafety",
  "length": null,
  "manual": null,
  "nPerPall": null,
  "bundle": 10,
  "name": "Konsoll Snörasskydd Bender Safe",
  "series": null,
  "subtext": "",
  "type": "snowSafetyConsole",
  "variants": [
    { artNumber: "170320", color: 'Svart'},
    { artNumber: "170324", color: 'Tegelröd'},
    { artNumber: "170390", color: 'Galvad'},
  ],
  "weight": 1.78
},
{
  "width": 420,
  "height": null,
  "id": 9,
  "imageUrl": "roofBridge",
  "length": 1050,
  "manual": null,
  "nPerPall": 50,
  "bundle": null,
  "name": "Takbrygga Bender Safe 1,05m",
  "series": null,
  "subtext": "",
  "type": "roofBridgeShort",
  "variants": [
    { artNumber: "170220", color: 'Svart'},
    { artNumber: "170224", color: 'Tegelröd'},
    { artNumber: "170290", color: 'Galvad'},
  ],
  "weight": 7.11
},
{
  "width": 420,
  "height": null,
  "id": 10,
  "imageUrl": "roofBridge",
  "length": 2100,
  "manual": null,
  "nPerPall": 50,
  "bundle": null,
  "name": "Takbrygga Bender Safe 2,1m",
  "series": null,
  "subtext": "",
  "type": "roofBridge",
  "variants": [
    { artNumber: "172220", color: 'Svart'},
    { artNumber: "172224", color: 'Tegelröd'},
    { artNumber: "172290", color: 'Galvad'},
  ],
  "weight": 12.4
},
{
  "width": null,
  "height": null,
  "id": 11,
  "imageUrl": "roofBridge",
  "length": null,
  "manual": null,
  "nPerPall": 80,
  "bundle": 5,
  "name": "Konsoll Takbrygga Bender Safe",
  "series": null,
  "subtext": "",
  "type": "roofBridgeConsole",
  "variants": [
    { artNumber: "170120", color: 'Svart'},
    { artNumber: "170124", color: 'Tegelröd'},
    { artNumber: "170190", color: 'Galvad'},
  ],
  "weight": 2.83
},
{
  "width": null,
  "height": null,
  "id": 12,
  "imageUrl": "roofRail",
  "length": 2100,
  "manual": null,
  "nPerPall": null,
  "bundle": null,
  "name": "Nockräcke Bender Safe 2x1,05m",
  "series": null,
  "subtext": "",
  "type": "roofRail",
  "variants": [
    { artNumber: "170620", color: 'Svart'},
    { artNumber: "170624", color: 'Tegelröd'},
    { artNumber: "170690", color: 'Galvad'},
  ],
  "weight": 2.5
},
{
  "width": null,
  "height": null,
  "id": 14,
  "imageUrl": "roofRail",
  "length": null,
  "manual": null,
  "nPerPall": 104,
  "bundle": 10,
  "name": "Konsoll Nockräcke Bender Safe",
  "series": null,
  "subtext": "",
  "type": "roofRailConsole",
  "variants": [
    { artNumber: "170520", color: 'Svart'},
    { artNumber: "170524", color: 'Tegelröd'},
    { artNumber: "170590", color: 'Galvad'},
  ],
  "weight": 1.43
},
{
  "width": null,
  "height": null,
  "id": 14,
  "imageUrl": "roofHatchSafety",
  "length": 1900,
  "manual": null,
  "nPerPall": null,
  "bundle": null,
  "name": "Skyddsräckesrör Safe Taklucka",
  "series": null,
  "subtext": "",
  "type": "roofHatchSafety",
  "variants": [
    { artNumber: "170820", color: 'Svart'},
    { artNumber: "170824", color: 'Tegelröd'},
    { artNumber: "170890", color: 'Galvad'},
  ],
  "weight": 7
},
{
  "width": null,
  "height": null,
  "id": 15,
  "imageUrl": "roofHatchSafety",
  "length": null,
  "manual": null,
  "nPerPall": 30,
  "bundle": null,
  "name": "Bender Safe konsollpaket till skyddsräcke",
  "series": null,
  "subtext": "",
  "type": "roofHatchSafetyConsole",
  "variants": [
    { artNumber: "170720", color: 'Svart'},
    { artNumber: "170724", color: 'Tegelröd'},
    { artNumber: "170790", color: 'Galvad'},
  ],
  "weight": 11.2
},
{
  "width": null,
  "height": null,
  "id": 16,
  "imageUrl": "footplate",
  "length": null,
  "manual": null,
  "nPerPall": null,
  "bundle": 10,
  "name": "Fotplatta",
  "series": null,
  "subtext": "",
  "type": "footplate",
  "variants": [
    {
      artNumber: "170990", 
      name: "Fotplatta 1&2-kup, Hansa, Tvilling Bender Safe", 
      color: 'Galvad', 
      fits:['Palema 2-kupig', 'Exklusiv 1-kupig', 'Tvilling 2-kupig', 'Hansa 1-kupig'],
      nPerPall: 150,
      weight: 1.37,
    },
    {
      artNumber: "171090",
      name: "Fotplatta Carisma, Piano Bender Safe", 
      color: 'Galvad', 
      fits:['Carisma platt', 'Piano platt'],
      nPerPall: 100,
      weight: 1.42,
    },
  ],
  "weight": null
},
{
  "width": 280,
  "cc":250,
  "height": null,
  "id": 17,
  "imageUrl": "tracktile",
  "length": 420,
  "manual": null,
  "nPerPall": 90,
  "bundle": null,
  "name": "Spårpanna betong Carisma höger",
  "series": "Carisma platt",
  "subtext": "",
  "type": "tracktile",
  "variants": [
    { artNumber: "C73421", family: 'Carisma', treatment: 'Benderit', color: 'Granit'},
    { artNumber: "C73428", family: 'Carisma', treatment: 'Benderit', color: 'Mellangr\u00e5'},
    { artNumber: "C73424", family: 'Carisma', treatment: 'Benderit', color: 'Tegelr\u00f6d'},
    { artNumber: "C73451", family: 'Carisma', treatment: 'Brilliant', color: 'Skiffer'},
    { artNumber: "C73401", family: 'Carisma', treatment: 'Obehandlad', color: 'Grafit'},
  ],
  "weight": 4.4
},
{
  "width": 280,
  "cc":250,
  "height": null,
  "id": 18,
  "imageUrl": "tracktile",
  "length": 420,
  "manual": null,
  "nPerPall": 90,
  "bundle": null,
  "name": "Spårpanna betong Carisma vänster",
  "series": "Carisma platt",
  "subtext": "",
  "type": "tracktile",
  "variants": [
    { artNumber: "C73521", family: 'Carisma', treatment: 'Benderit', color: 'Granit'},
    { artNumber: "C73528", family: 'Carisma', treatment: 'Benderit', color: 'Mellangr\u00e5'},
    { artNumber: "C73524", family: 'Carisma', treatment: 'Benderit', color: 'Tegelr\u00f6d'},
    { artNumber: "C73551", family: 'Carisma', treatment: 'Brilliant', color: 'Skiffer'},
    { artNumber: "C73501", family: 'Carisma', treatment: 'Obehandlad', color: 'Grafit'},
  ],
  "weight": 4.4
},
{
  "width": 280,
  "cc":250,
  "height": 100,
  "id": 19,
  "imageUrl": "tracktile",
  "length": 420,
  "manual": null,
  "nPerPall": 90,
  "bundle": null,
  "name": "Spårpanna betong Exklusiv",
  "series": "Exklusiv 1-kupig",
  "subtext": "",
  "type": "tracktile",
  "variants": [
    { artNumber: "073422", family: 'Exklusiv', treatment: 'Benderit', color: 'Brun'},
    { artNumber: "073429", family: 'Exklusiv', treatment: 'Benderit', color: 'Flash tegelr\u00f6d/brun'},
    { artNumber: "073423", family: 'Exklusiv', treatment: 'Benderit', color: 'Gr\u00f6n'},
    { artNumber: "073425", family: 'Exklusiv', treatment: 'Benderit', color: 'Gul'},
    { artNumber: "073428", family: 'Exklusiv', treatment: 'Benderit', color: 'Mellangr\u00e5'},
    { artNumber: "073426", family: 'Exklusiv', treatment: 'Benderit', color: 'R\u00f6d'},
    { artNumber: "073420", family: 'Exklusiv', treatment: 'Benderit', color: 'Svart'},
    { artNumber: "073424", family: 'Exklusiv', treatment: 'Benderit', color: 'Tegelr\u00f6d'},
    { artNumber: "073451", family: 'Exklusiv', treatment: 'Brilliant', color: 'Skiffer'},
    { artNumber: "073488", family: 'Exklusiv', treatment: 'Candor', color: 'Mellangr\u00e5'},
    { artNumber: "073498", family: 'Exklusiv', treatment: 'Candor', color: 'M\u00f6rkgr\u00e5'},
    { artNumber: "073480", family: 'Exklusiv', treatment: 'Candor', color: 'Svart'},
    { artNumber: "073484", family: 'Exklusiv', treatment: 'Candor', color: 'Tegelr\u00f6d'},
    { artNumber: "073486", family: 'Exklusiv', treatment: 'Candor', color: 'Terracottar\u00f6d'},
    { artNumber: "073401", family: 'Exklusiv', treatment: 'Obehandlad', color: 'Grafit'},
    { artNumber: "073419", family: 'Exklusiv', treatment: 'Obehandlad', color: 'R\u00f6d standard'},
  ],
  "weight": 3.9,
},
{
  "width": 330,
  "cc":300,
  "height": null,
  "id": 20,
  "imageUrl": "tracktile",
  "length": 420,
  "manual": null,
  "nPerPall": 120,
  "bundle": null,
  "name": "Spårpanna betong Palema",
  "series": "Palema 2-kupig",
  "subtext": "",
  "type": "tracktile",
  "variants": [
    { artNumber: "073622", family: 'Palema', treatment: 'Benderit', color: 'Brun'},
    { artNumber: "073629", family: 'Palema', treatment: 'Benderit', color: 'Flash tegelr\u00f6d/brun'},
    { artNumber: "073623", family: 'Palema', treatment: 'Benderit', color: 'Gr\u00f6n'},
    { artNumber: "073625", family: 'Palema', treatment: 'Benderit', color: 'Gul'},
    { artNumber: "073637", family: 'Palema', treatment: 'Benderit', color: 'Ljusgr\u00e5'},
    { artNumber: "073628", family: 'Palema', treatment: 'Benderit', color: 'Mellangr\u00e5'},
    { artNumber: "073648", family: 'Palema', treatment: 'Benderit', color: 'M\u00f6rkgr\u00e5'},
    { artNumber: "073626", family: 'Palema', treatment: 'Benderit', color: 'R\u00f6d'},
    { artNumber: "073620", family: 'Palema', treatment: 'Benderit', color: 'Svart'},
    { artNumber: "073624", family: 'Palema', treatment: 'Benderit', color: 'Tegelr\u00f6d'},
    { artNumber: "073631", family: 'Palema', treatment: 'Benderit', color: 'Vinr\u00f6d'},
    { artNumber: "073655", family: 'Palema', treatment: 'Brilliant', color: 'Koppar'},
    { artNumber: "073651", family: 'Palema', treatment: 'Brilliant', color: 'Skiffer'},
    { artNumber: "073682", family: 'Palema', treatment: 'Candor', color: 'Kastanjebrun'},
    { artNumber: "073688", family: 'Palema', treatment: 'Candor', color: 'Mellangr\u00e5'},
    { artNumber: "073698", family: 'Palema', treatment: 'Candor', color: 'M\u00f6rkgr\u00e5'},
    { artNumber: "073683", family: 'Palema', treatment: 'Candor', color: 'Olivgr\u00f6n'},
    { artNumber: "073680", family: 'Palema', treatment: 'Candor', color: 'Svart'},
    { artNumber: "073684", family: 'Palema', treatment: 'Candor', color: 'Tegelr\u00f6d'},
    { artNumber: "073686", family: 'Palema', treatment: 'Candor', color: 'Terracottar\u00f6d'},
    { artNumber: "073628E", family: 'Palema', treatment: 'ECO-line Benderit', color: 'Mellangr\u00e5'},
    { artNumber: "073624E", family: 'Palema', treatment: 'ECO-line Benderit', color: 'Tegelr\u00f6d'},
    { artNumber: "073614", family: 'Palema', treatment: 'Obehandlad', color: 'Antik tegelr\u00f6d standard/svart'},
    { artNumber: "073601", family: 'Palema', treatment: 'Obehandlad', color: 'Grafit'},
    { artNumber: "073619", family: 'Palema', treatment: 'Obehandlad', color: 'R\u00f6d standard'},
    { artNumber: "073614E", family: 'Palema', treatment: 'ECO-line Obehandlad', color: 'Tegelr\u00f6d standard'},
    { artNumber: "073678", family: 'Palema', treatment: 'Struktur', color: 'Mellangr\u00e5'},
    { artNumber: "073676", family: 'Palema', treatment: 'Struktur', color: 'R\u00f6d'},
    { artNumber: "073670", family: 'Palema', treatment: 'Struktur', color: 'Svart'},
    { artNumber: "073674", family: 'Palema', treatment: 'Struktur', color: 'Tegelr\u00f6d'},
  ],
  "weight": 4.05
},
{
  "width": 264,
  "cc":211,
  "height": 100,
  "id": 21,
  "imageUrl": "tracktile",
  "length": 425,
  "manual": null,
  "nPerPall": 90,
  "bundle": null,
  "name": "Spårpanna lertegel Hansa",
  "series": "Hansa 1-kupig",
  "subtext": "",
  "type": "tracktile",
  "variants": [
    { artNumber: "H73524", family: 'Hansa', treatment: 'Natur', color: 'Naturr\u00f6d'},
  ],
  "weight": 2.9,
},
{
  "width": 264,
  "cc":211,
  "height": 100,
  "id": 22,
  "imageUrl": "tracktile",
  "length": 425,
  "manual": null,
  "nPerPall": 90,
  "bundle": null,
  "name": "Spårpanna lertegel Hansa början",
  "series": "Hansa 1-kupig",
  "subtext": "",
  "type": "tracktile",
  "variants": [
    { artNumber: "H73424", family: 'Hansa', treatment: 'Natur', color: 'Naturr\u00f6d'},
  ],
  "weight": 2.9,
},
{
  "width": 264,
  "cc":211,
  "height": 100,
  "id": 23,
  "imageUrl": "tracktile",
  "length": 425,
  "manual": null,
  "nPerPall": 90,
  "bundle": null,
  "name": "Spårpanna lertegel Hansa slut",
  "series": "Hansa 1-kupig",
  "subtext": "",
  "type": "tracktile",
  "variants": [
    { artNumber: "H73624", family: 'Hansa', treatment: 'Natur', color: 'Naturr\u00f6d'},
  ],
  "weight": 2.9,
},
{
  "width": 398,
  "cc":346,
  "height": 100,
  "id": 24,
  "imageUrl": "tracktile",
  "length": 398,
  "manual": null,
  "nPerPall": 52,
  "bundle": null,
  "name": "Spårpanna lertegel Tvilling",
  "series": "Tvilling 2-kupig",
  "subtext": "",
  "type": "tracktile",
  "variants": [
    { artNumber: "H72524", family: 'Hansa', treatment: 'Natur', color: 'Naturr\u00f6d'},
  ],
  "weight": 4.5,
},
{
  "width": 398,
  "cc":346,
  "height": 100,
  "id": 25,
  "imageUrl": "tracktile",
  "length": 398,
  "manual": null,
  "nPerPall": 52,
  "bundle": null,
  "name": "Spårpanna lertegel Tvilling början",
  "series": "Tvilling 2-kupig",
  "subtext": "",
  "type": "tracktile",
  "variants": [
    { artNumber: "H72424", family: 'Hansa', treatment: 'Natur', color: 'Naturr\u00f6d'},
  ],
  "weight": 4.5,
},
{
  "width": 398,
  "cc":346,
  "height": 100,
  "id": 26,
  "imageUrl": "tracktile",
  "length": 398,
  "manual": null,
  "nPerPall": 52,
  "bundle": null,
  "name": "Spårpanna lertegel Tvilling slut",
  "series": "Tvilling 2-kupig",
  "subtext": "",
  "type": "tracktile",
  "variants": [
    { artNumber: "H72624", family: 'Hansa', treatment: 'Natur', color: 'Naturr\u00f6d'},
  ],
  "weight": 4.5,
},
{
  "width": 0,
  "cc": 0,
  "height": 0,
  "id": 27,
  "imageUrl": "snowsafety",
  "length": 2330,
  "manual": null,
  "nPerPall": 140,
  "bundle": null,
  "name": "Snörasskydd profildurk 2,33 meter",
  "series": null,
  "subtext": "",
  "type": "snowSafetyPW",
  "variants": [
    { artNumber: "139624", color: 'Tegelr\u00f6d'},
    { artNumber: "139620", color: 'Svart'},
    { artNumber: "139690", color: 'Galvad'},
  ],
  "weight": 5,
},
{
  "width": 0,
  "cc": 0,
  "height": 0,
  "id": 28,
  "imageUrl": "",
  "length": null,
  "manual": null,
  "nPerPall": 0,
  "bundle": null,
  "name": "Fotplatta inkl. hållare 1o2-kup Btg+Hansa+Tvilling",
  "series": null,
  "subtext": "",
  "type": "footplatHansa",
  "variants": [
    { artNumber: "134824", color: 'Tegelr\u00f6d'},
    { artNumber: "134820", color: 'Svart'},
    { artNumber: "134890", color: 'Galvad'},
  ],
  "weight": 0,
},
{
  "width": 0,
  "cc": 0,
  "height": 0,
  "id": 29,
  "imageUrl": "",
  "length": null,
  "manual": null,
  "nPerPall": 0,
  "bundle": null,
  "name": "Fäste för bärläkt 2-kupig",
  "series": null,
  "subtext": "",
  "type": "fastener2Kup",
  "variants": [
    { artNumber: "137624", color: 'Tegelr\u00f6d'},
    { artNumber: "137620", color: 'Svart'},
    { artNumber: "137690", color: 'Galvad'},
  ],
  "weight": 0,
},
{
  "width": 0,
  "cc": 0,
  "height": 0,
  "id": 30,
  "imageUrl": "",
  "length": null,
  "manual": null,
  "nPerPall": 0,
  "bundle": null,
  "name": "Fotplatta inkl. hållare Carisma",
  "series": null,
  "subtext": "",
  "type": "footplatCarisma",
  "variants": [
    { artNumber: "C134824", color: 'Tegelr\u00f6d'},
    { artNumber: "C134820", color: 'Svart'},
    { artNumber: "C134890", color: 'Galvad'},
  ],
  "weight": 0,
},
{
  "width": 0,
  "cc": 0,
  "height": 0,
  "id": 31,
  "imageUrl": "",
  "length": null,
  "manual": null,
  "nPerPall": 0,
  "bundle": null,
  "name": "Fotplatta inkl. hållare 1o2-kup Strängpressat lertegel",
  "series": null,
  "subtext": "",
  "type": "footplatHöganäs",
  "variants": [
    { artNumber: "L134824", color: 'Tegelr\u00f6d'},
    { artNumber: "L134820", color: 'Svart'},
    { artNumber: "L134890", color: 'Galvad'},
  ],
  "weight": 0,
},
{
  "width": 0,
  "cc": 0,
  "height": 0,
  "id": 32,
  "imageUrl": "",
  "length": null,
  "manual": null,
  "nPerPall": 0,
  "bundle": null,
  "name": "Låssats snörasskydd",
  "series": null,
  "subtext": "",
  "type": "snowLockingSet",
  "variants": [
    { artNumber: "139724", color: 'Tegelr\u00f6d'},
    { artNumber: "139720", color: 'Svart'},
    { artNumber: "139790", color: 'Galvad'},
  ],
  "weight": 0,
},
{
  "width": 0,
  "cc": 0,
  "height": 0,
  "id": 33,
  "imageUrl": "",
  "length": null,
  "manual": null,
  "nPerPall": 0,
  "bundle": null,
  "name": "Skruvar till fotplatta/bärläktsfäste 10 st 6x35mm",
  "series": null,
  "subtext": "",
  "type": "battenScrews",
  "variants": [
    { artNumber: "136090", color: 'Galvad' },
  ],
  "weight": 0,
},
{
  "width": 0,
  "cc": 0,
  "height": 0,
  "id": 34,
  "imageUrl": "",
  "length": null,
  "manual": null,
  "nPerPall": 0,
  "bundle": null,
  "name": "Bult/skarv 4st M10x20+mutter",
  "series": null,
  "subtext": "",
  "type": "jointBolt",
  "variants": [
    { artNumber: "132900", color: 'Galvad' },
  ],
  "weight": 0,
},
{
  "width": 0,
  "cc": 0,
  "height": 0,
  "id": 35,
  "imageUrl": "",
  "length": 2310,
  "manual": null,
  "nPerPall": 0,
  "bundle": null,
  "name": "Takbrygga profildurk 2,31 m",
  "series": null,
  "subtext": "",
  "type": "roofBridgePW",
  "variants": [
    { artNumber: "137820", color: 'Svart' },
    { artNumber: "137824", color: 'Tegelr\u00f6d' },
    { artNumber: "137890", color: 'Galvad' },
  ],
  "weight": 0,
},
{
  "width": 0,
  "cc": 0,
  "height": 0,
  "id": 36,
  "imageUrl": "",
  "length": 1190,
  "manual": null,
  "nPerPall": 0,
  "bundle": null,
  "name": "Takbrygga profildurk 1,19 m",
  "series": null,
  "subtext": "",
  "type": "roofBridgeShortPW",
  "variants": [
    { artNumber: "137720", color: 'Svart' },
    { artNumber: "137724", color: 'Tegelr\u00f6d' },
    { artNumber: "137790", color: 'Galvad' },
  ],
  "weight": 0,
},
{
  "width": 0,
  "cc": 0,
  "height": 0,
  "id": 37,
  "imageUrl": "",
  "length": null,
  "manual": null,
  "nPerPall": 0,
  "bundle": null,
  "name": "Stag brygga 1st/sträcka inkl. 1 st. M10x20+mutter",
  "series": null,
  "subtext": "",
  "type": "stagBridge",
  "variants": [
    { artNumber: "134320", color: 'Svart' },
    { artNumber: "134324", color: 'Tegelr\u00f6d' },
    { artNumber: "134390", color: 'Galvad' },
  ],
  "weight": 0,
},
{
  "width": 0,
  "cc": 0,
  "height": 0,
  "id": 38,
  "imageUrl": "",
  "length": null,
  "manual": null,
  "nPerPall": 0,
  "bundle": null,
  "name": "Överdel Takbrygga",
  "series": null,
  "subtext": "",
  "type": "roofBridgeUpper",
  "variants": [
    { artNumber: "136920", color: 'Svart' },
    { artNumber: "136924", color: 'Tegelr\u00f6d' },
    { artNumber: "136990", color: 'Galvad' },
  ],
  "weight": 0,
},
{
  "width": 0,
  "cc": 0,
  "height": 0,
  "id": 39,
  "imageUrl": "",
  "length": 2300,
  "manual": null,
  "nPerPall": 150,
  "bundle": null,
  "name": "Nockräcke 27 mm L=2300 mm",
  "series": null,
  "subtext": "",
  "type": "roofRailPW",
  "variants": [
    { artNumber: "130020", color: 'Svart' },
    { artNumber: "130024", color: 'Tegelr\u00f6d' },
    { artNumber: "130090", color: 'Galvad' },
  ],
  "weight": 2.2,
},
{
  "width": 0,
  "cc": 0,
  "height": 0,
  "id": 40,
  "imageUrl": "",
  "length": null,
  "manual": null,
  "nPerPall": 0,
  "bundle": null,
  "name": "Låsning rör komplett",
  "series": null,
  "subtext": "",
  "type": "roofRailLockingSet",
  "variants": [
    { artNumber: "130820", color: 'Svart' },
    { artNumber: "130824", color: 'Tegelr\u00f6d' },
    { artNumber: "130890", color: 'Galvad' },
  ],
  "weight": 0.14,
},
{
  "width": 0,
  "cc": 0,
  "height": 0,
  "id": 41,
  "imageUrl": "",
  "length": null,
  "manual": null,
  "nPerPall": 0,
  "bundle": null,
  "name": "Skruv till Nockräcke 15,5x22mm 10st/pkt",
  "series": null,
  "subtext": "",
  "type": "roofRailScrews",
  "variants": [
    { artNumber: "130120", color: 'Svart' },
    { artNumber: "130124", color: 'Tegelr\u00f6d' },
    { artNumber: "130190", color: 'Galvad' },
  ],
  "weight": 0.14,
},
{
  "width": 0,
  "cc": 0,
  "height": 0,
  "id": 42,
  "imageUrl": "",
  "length": null,
  "manual": null,
  "nPerPall": 0,
  "bundle": null,
  "name": "Naket skyddsräcke till taklucka",
  "series": null,
  "subtext": "",
  "type": "roofHatchRail",
  "variants": [
    { artNumber: "135120", color: 'Svart' },
    { artNumber: "135124", color: 'Tegelr\u00f6d' },
    { artNumber: "135190", color: 'Galvad' },
  ],
  "weight": 9.3,
},
]
